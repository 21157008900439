export const mapLayers = {
  code: 0,
  message: "success",
  data: {
    list: [{
      id: "private_layer",
      name: "Private Layer",
      order: 0,
      is_distributed: false,
      type: 1,
      is_lock: false,
      create_time: 1634268707424,
      elements: [{
        id: "b2370d29-be65-42b0-9224-4d816e86dc64",
        name: "xuejia n. 1",
        order: 0,
        status: 1,
        display: 1,
        resource: {
          content: {
            type: "Feature",
            properties: {
              color: "#2D8CF0"
            },
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [114.156671, 38.468249],
                  [114.139517, 37.372177],
                  [115.52899, 37.712212]
                ]
              ]
            }
          },
          type: 4,
          user_name: "xuejia n.",
          user_id: "1402914943455727616"
        },
        update_time: 1636966336566,
        create_time: 1636966325700,
        elevation_load_status: 0,
        icon: "area"
      }, {
        id: "768e9fcd-121f-47a6-96b9-f1aee27d32f0",
        name: "xuejia n. 1",
        order: 0,
        status: 1,
        display: 1,
        resource: {
          content: {
            type: "Feature",
            properties: {
              color: "#2D8CF0"
            },
            geometry: {
              type: "LineString",
              coordinates: [
                [116.263962, 40.234929],
                [116.503006, 40.237026],
                [116.335465, 40.155206],
                [116.541458, 40.12371]
              ]
            }
          },
          type: 4,
          user_name: "xuejia n.",
          user_id: "1402914943455727616"
        },
        update_time: 1636966322636,
        create_time: 1636966316803,
        elevation_load_status: 0,
        icon: "line"
      }, {
        id: "4e741a76-3600-4af5-ace8-d805e7cd31fa",
        name: "xuejia n. 2",
        order: 0,
        status: 1,
        display: 1,
        resource: {
          content: {
            type: "Feature",
            properties: {
              color: "#2D8CF0",
              clampToGround: true
            },
            geometry: {
              type: "Point",
              coordinates: [116.098223, 39.976538, 104]
            }
          },
          type: 6,
          user_name: "xuejia n.",
          user_id: "1402914943455727616"
        },
        update_time: 1636966305229,
        create_time: 1636966305229,
        elevation_load_status: 0,
        icon: "pin"
      }, {
        id: "efff2b5d-de22-4d48-8d92-4f53170668f6",
        name: "xuejia n. 1",
        order: 0,
        status: 1,
        display: 1,
        resource: {
          content: {
            type: "Feature",
            properties: {
              color: "#19BE6B",
              clampToGround: true
            },
            geometry: {
              type: "Point",
              coordinates: [113.35367028239645, 23.755194000519843, 22]
            }
          },
          type: 6,
          user_name: "xuejia n.",
          user_id: "1402914943455727616"
        },
        update_time: 1636966304432,
        create_time: 1636966299455,
        elevation_load_status: 0,
        icon: "pin"
      }]
    }, {
      id: "share_layer",
      name: "Share Layer",
      order: 0,
      is_distributed: true,
      type: 2,
      is_lock: false,
      create_time: 1634268707414,
      elements: []
    }]
  }
}
