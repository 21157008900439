export const CURRENT_CONFIG = {

  // license
  appId: import.meta.env.VITE_APPID, // You need to go to the development website to apply.
  appKey: import.meta.env.VITE_APPKEY, // You need to go to the development website to apply.
  appLicense: import.meta.env.VITE_APPLICENSE, // You need to go to the development website to apply.

  // http
  websocketURL: import.meta.env.VITE_API_WEBSOCKET, // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  baseURL: import.meta.env.VITE_API_URL, // This url must end with "/". Example: 'http://192.168.1.1:6789/'   http://1.15.180.200:6789/
  baseURLLVPAI: import.meta.env.VITE_API_URL_LVPAI, // This url must end with "/". Example: 'http://192.168.1.1:6789/'   http://1.15.180.200:6789/
  baseURLRENGONGLVPAI: import.meta.env.VITE_API_URL_RENGONGLVPAI, // This url must end with "/". Example: 'http://192.168.1.1:6789/'   http://1.15.180.200:6789/

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: import.meta.env.VITE_API_RTMP, // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: "Please enter the server ip.",
  gbServerPort: "Please enter the server port.",
  gbServerId: "Please enter the server id.",
  gbAgentId: "Please enter the agent id",
  gbPassword: "Please enter the agent password",
  gbAgentPort: "Please enter the local port.",
  gbAgentChannel: "Please enter the channel.",
  // RTSP
  rtspUserName: "Please enter the username.",
  rtspPassword: "Please enter the password.",
  rtspPort: "8554",
  // Agora
  agoraAPPID: "Please enter the agora app id.",
  agoraToken: "Please enter the agora temporary token.",
  agoraChannel: "Please enter the agora channel.",

  // map
  // You can apply on the AMap website.
  amapKey: "62df8b09a19aa6861979d775063d5542",

}
