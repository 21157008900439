import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { ERouterName, KJRouterName } from "/@/types/index"

const routes: Array<RouteRecordRaw> = [
  // 重定向至登录
  {
    path: "/",
    redirect: "/" + ERouterName.PROJECT,
    // redirect: "/" + ERouterName.PILOT,
  },
  // 登录页
  {
    path: "/" + ERouterName.PROJECT,
    name: ERouterName.PROJECT,
    component: () => import("/@/pages/page-web/index.vue"),
  },
  // pilot
  {
    path: "/" + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import("/@/pages/page-pilot/pilot-index.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_HOME,
    component: () => import("/@/pages/page-pilot/pilot-home.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_MEDIA,
    component: () => import("/@/pages/page-pilot/pilot-media.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_LIVESHARE,
    component: () => import("/@/pages/page-pilot/pilot-liveshare.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_BIND,
    component: () => import("/@/pages/page-pilot/pilot-bind.vue"),
  },
  // 其他路由...
  // {
  //   path: "/:pathMatch(.*)*",
  //   component: () => import("/@/pages/404.vue"),
  // },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// 在路由初始化时恢复动态路由
const initializeRouter = async () => {
  const selectedRouteType = localStorage.getItem("selectedRouteType")
  if (selectedRouteType === "demo") {
    const demoRoutes = (await import("/@/router/demo")).default
    demoRoutes.forEach((route) => router.addRoute(route))
  } else {
    const kongjianRoutes = (await import("/@/router/kongjian")).default
    kongjianRoutes.forEach((route) => router.addRoute(route))
  }

  // 检查当前路由是否匹配
  if (router.currentRoute.value.matched.length === 0) {
    router.replace(router.currentRoute.value.path)
  }

  // 重新检查当前路径
  // if (!router.currentRoute.value.matched.length) {
  //   router.replace(router.currentRoute.value.path);
  // }
}

initializeRouter()

export default router
