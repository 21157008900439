<template>
  <div class="demo-app">
      <router-view />
  </div>
</template>

<script lang="ts">
import { onMounted, defineComponent } from "vue"
// 使用 inject 来接收 provide 的 locale
export default defineComponent({
  name: "App",
  // components: { GMap },

  setup () {
    // 使用 inject 来接收 provide 的 locale
    // const store = useMyStore()
    return { }
  },
})
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%;
}
</style>
